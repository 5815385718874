/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/docs/en/main/file-conventions/entry.client
 */

import React, { useState, useEffect } from 'react'

import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";

import { CacheProvider } from '@emotion/react'
import { ClientStyleContext } from './context'
import createEmotionCache, { defaultCache } from './createEmotionCache'

function ClientCacheProvider({ children }) {
  const [cache, setCache] = useState(defaultCache)

  function reset() {
    setCache(createEmotionCache())
  }

  useEffect(() => {
    /*
    const consentJs = document.createElement("script");
    consentJs.src = "https://cdn.websitepolicies.io/lib/cconsent/cconsent.min.js";
    consentJs.defer = true;
    document.body.appendChild(consentJs);
    */
  }, [])

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  )
}

startTransition(() => {
  hydrateRoot(
    document,
    // <StrictMode>
    <ClientCacheProvider>
      <RemixBrowser />
    </ClientCacheProvider>
    // </StrictMode>
  );
});
